// src/pages/thongbao.js
import React from 'react';
import '../css/thongbao.css'
export default function ThongBao() {
    return (
        <div className="notification-page">
            <p>There are no order updates yet</p>
        </div>
    );
}
