// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* engagementRings.css */
.custom-container {
    max-width: 1800px;
    margin: 0 auto;
}

.explore-diamond-banner {
    text-align: center;
    padding: 20px;
    width: 100%;
}

.scroll-bar .ER-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    text-align: center;
}

.scroll-bar .ER-items img {
    width: 100%;
    max-width: 180px;
    height: auto;
    margin-bottom: 10px;
}

.carousel-item-padding-40-px {
    padding: 0 10px;
}

@media (max-width: 768px) {
    .ER-items img {
        width: 80px;
        height: 80px;
    }

    .ER-items p {
        font-size: 0.8rem;
    }

    .banner-content p {
        padding-left: 0;
    }
}`, "",{"version":3,"sources":["webpack://./src/css/engagementRings.css"],"names":[],"mappings":"AAAA,wBAAwB;AACxB;IACI,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,WAAW;AACf;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,gBAAgB;IAChB,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI;QACI,WAAW;QACX,YAAY;IAChB;;IAEA;QACI,iBAAiB;IACrB;;IAEA;QACI,eAAe;IACnB;AACJ","sourcesContent":["/* engagementRings.css */\n.custom-container {\n    max-width: 1800px;\n    margin: 0 auto;\n}\n\n.explore-diamond-banner {\n    text-align: center;\n    padding: 20px;\n    width: 100%;\n}\n\n.scroll-bar .ER-items {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    padding: 10px;\n    text-align: center;\n}\n\n.scroll-bar .ER-items img {\n    width: 100%;\n    max-width: 180px;\n    height: auto;\n    margin-bottom: 10px;\n}\n\n.carousel-item-padding-40-px {\n    padding: 0 10px;\n}\n\n@media (max-width: 768px) {\n    .ER-items img {\n        width: 80px;\n        height: 80px;\n    }\n\n    .ER-items p {\n        font-size: 0.8rem;\n    }\n\n    .banner-content p {\n        padding-left: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
