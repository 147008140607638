// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Add the following styles to your hoso.css */
.user-info {
    display: flex;
    flex-direction: column;
    margin-left: 10%; /* Align to the right */
    width: 80%; /* Set the width to 80% */
    padding: 20px; /* Optional: Add padding */
}

.form-group {
    margin-bottom: 20px;
}

.custom-text-field {
    width: 100%;
    padding-top: 1%;
    padding-bottom: 2%;
    font-size: 18px;
}

.radio-group {
    display: flex;
    justify-content: space-between;
    width: 50%; /* Adjust width as needed */
}

.radio-group label {
    margin-right: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/userInfoContent/css/hoso.css"],"names":[],"mappings":"AAAA,8CAA8C;AAC9C;IACI,aAAa;IACb,sBAAsB;IACtB,gBAAgB,EAAE,uBAAuB;IACzC,UAAU,EAAE,yBAAyB;IACrC,aAAa,EAAE,0BAA0B;AAC7C;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,eAAe;IACf,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,UAAU,EAAE,2BAA2B;AAC3C;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":["/* Add the following styles to your hoso.css */\n.user-info {\n    display: flex;\n    flex-direction: column;\n    margin-left: 10%; /* Align to the right */\n    width: 80%; /* Set the width to 80% */\n    padding: 20px; /* Optional: Add padding */\n}\n\n.form-group {\n    margin-bottom: 20px;\n}\n\n.custom-text-field {\n    width: 100%;\n    padding-top: 1%;\n    padding-bottom: 2%;\n    font-size: 18px;\n}\n\n.radio-group {\n    display: flex;\n    justify-content: space-between;\n    width: 50%; /* Adjust width as needed */\n}\n\n.radio-group label {\n    margin-right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
