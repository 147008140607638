// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-container {
  padding: 24px;
  background-color: #f5f5f5;
}

.dashboard-paper {
  padding: 16px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.dashboard-chart {
  height: 400px;
}

.dashboard-deposits {
  height: 400px;
}

.dashboard-orders {
  height: auto;
  max-height: 400px; /* Ensure it fits well */
  overflow-y: auto; /* Allow scrolling if content overflows */
}
`, "",{"version":3,"sources":["webpack://./src/components/dashboard/dashboardContent/DashboardContent.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,iBAAiB,EAAE,wBAAwB;EAC3C,gBAAgB,EAAE,yCAAyC;AAC7D","sourcesContent":[".dashboard-container {\n  padding: 24px;\n  background-color: #f5f5f5;\n}\n\n.dashboard-paper {\n  padding: 16px;\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n}\n\n.dashboard-chart {\n  height: 400px;\n}\n\n.dashboard-deposits {\n  height: 400px;\n}\n\n.dashboard-orders {\n  height: auto;\n  max-height: 400px; /* Ensure it fits well */\n  overflow-y: auto; /* Allow scrolling if content overflows */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
