// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/Tracklog.css */
.root {
    width: 90%;
    margin: auto;
    margin-top: 20px;
    padding: 20px;
    background-color: #e3f2fd;
    border-radius: 10px;
}

.stepper {
    padding: 20px 0;
}

.button {
    margin-top: 20px;
    background-color: black;
    color: white;
}

.button:hover {
    background-color: #333;
}

.table {
    margin-top: 20px;
}

.tags-container {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.tag-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.tag-label {
    font-weight: bold;
    margin-right: 10px;
}

.tag-date {
    font-size: 0.875rem;
    color: #555;
}

.tracking-steps {
    display: flex;
    justify-content: space-around;
    margin: 20px 0;
}

.step {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 50%;
    width: 100px;
    text-align: center;
}

.completed {
    background-color: #4caf50;
    color: white;
}
`, "",{"version":3,"sources":["webpack://./src/components/userInfoContent/css/Tracklog.css"],"names":[],"mappings":"AAAA,gCAAgC;AAChC;IACI,UAAU;IACV,YAAY;IACZ,gBAAgB;IAChB,aAAa;IACb,yBAAyB;IACzB,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,uBAAuB;IACvB,YAAY;AAChB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,aAAa;IACb,6BAA6B;IAC7B,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,YAAY;AAChB","sourcesContent":["/* src/components/Tracklog.css */\n.root {\n    width: 90%;\n    margin: auto;\n    margin-top: 20px;\n    padding: 20px;\n    background-color: #e3f2fd;\n    border-radius: 10px;\n}\n\n.stepper {\n    padding: 20px 0;\n}\n\n.button {\n    margin-top: 20px;\n    background-color: black;\n    color: white;\n}\n\n.button:hover {\n    background-color: #333;\n}\n\n.table {\n    margin-top: 20px;\n}\n\n.tags-container {\n    display: flex;\n    flex-direction: column;\n    margin-top: 20px;\n}\n\n.tag-container {\n    display: flex;\n    align-items: center;\n    margin-bottom: 10px;\n}\n\n.tag-label {\n    font-weight: bold;\n    margin-right: 10px;\n}\n\n.tag-date {\n    font-size: 0.875rem;\n    color: #555;\n}\n\n.tracking-steps {\n    display: flex;\n    justify-content: space-around;\n    margin: 20px 0;\n}\n\n.step {\n    padding: 10px;\n    border: 1px solid #ccc;\n    border-radius: 50%;\n    width: 100px;\n    text-align: center;\n}\n\n.completed {\n    background-color: #4caf50;\n    color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
