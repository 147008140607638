export const routes = {
    homePage: '/',
    login: '/login',
    register: '/register',
    dashboard: '/dashboard-Page',
    orderPage: '/order-Page',
    feedbackPage: '/fb-Page',
    accountPage: '/account-Page',
    productPage: '/product-Page',
    revenuePage: '/revenue-Page',
    engagementRings: '/engagement-rings',
    shoppingCart: '/shopping-cart',
    userInfo: '/userinfo/*',
    detail: '/product-detail',
    checkout: '/checkout',  
    checkoutcomplete: '/checkout-complete',
    staffsite : '/staffSite',
    history : '/history',
    wishList: '/wish-list',
    ringSize: '/diamond-education/',
    diamond: '/diamond',
    necklace:'/necklace',
    // diamondEducation: '/diamond-education/',
    metalEdu: '/diamond-education/metal-education',
    fourCs: '/diamond-education/fourCs-education',
    cut:'/diamond-education/cut',
    color: '/diamond-education/color',
    clarity: '/diamond-education/clarity',
    carat:'/diamond-education/carat',
    EventPage:'/Event',
    ViewEventPage:'/ViewEvent',
    MoldManage: '/MoldManage',
    diamondPrice: '/diamond-price-list',
    warrantyPolicy: '/warranty-policy',
    privacyPolicy: '/privacy-policy',
    certificateManage: '/certificate-manage',
    warrantyManage: '/warranty-manage',
    resetPassword: '/login/reset-password',
    newPassword: '/login/enter-new-password',
    weddingRings: '/wedding-rings',
}