// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css-bhp9pd-MuiPaper-root-MuiCard-root{
    box-shadow: none !important;
}

.card{
    display: flex !important;
}`, "",{"version":3,"sources":["webpack://./src/components/education/css/ringSize.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;AAC/B;;AAEA;IACI,wBAAwB;AAC5B","sourcesContent":[".css-bhp9pd-MuiPaper-root-MuiCard-root{\n    box-shadow: none !important;\n}\n\n.card{\n    display: flex !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
