// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/styles.css */
body {
    font-family: Arial, sans-serif;
    background-color: #f0f2f5;
    margin: 0;
    padding: 0;
}

.userinfo-container {
    display: flex;
    height: 100vh;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.sidebar-user {
    width: 250px;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sidebar-user a {
    text-decoration: none;
    color: #1877f2;
    padding: 10px 20px;
    margin: 5px 0;
    border-radius: 5px;
    width: 100%;
    text-align: center;
    font-weight: bold;
}

.sidebar-user a.active,
.sidebar-user a:hover {
    background-color: #e7f3ff;
}

.content {
    flex: 1 1;
    padding: 40px;
    background-color: #f0f2f5;
}

.notification-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.empty-notification-image {
    width: 150px;
    height: 150px;
    margin-bottom: 20px;
}

.notification-page p {
    color: #555;
    font-size: 18px;
    text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/userInfoContent/css/thongbao.css"],"names":[],"mappings":"AAAA,mBAAmB;AACnB;IACI,8BAA8B;IAC9B,yBAAyB;IACzB,SAAS;IACT,UAAU;AACd;;AAEA;IACI,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,uCAAuC;AAC3C;;AAEA;IACI,YAAY;IACZ,sBAAsB;IACtB,aAAa;IACb,uCAAuC;IACvC,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,qBAAqB;IACrB,cAAc;IACd,kBAAkB;IAClB,aAAa;IACb,kBAAkB;IAClB,WAAW;IACX,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;;IAEI,yBAAyB;AAC7B;;AAEA;IACI,SAAO;IACP,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,eAAe;IACf,kBAAkB;AACtB","sourcesContent":["/* src/styles.css */\nbody {\n    font-family: Arial, sans-serif;\n    background-color: #f0f2f5;\n    margin: 0;\n    padding: 0;\n}\n\n.userinfo-container {\n    display: flex;\n    height: 100vh;\n    background-color: #fff;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n}\n\n.sidebar-user {\n    width: 250px;\n    background-color: #fff;\n    padding: 20px;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.sidebar-user a {\n    text-decoration: none;\n    color: #1877f2;\n    padding: 10px 20px;\n    margin: 5px 0;\n    border-radius: 5px;\n    width: 100%;\n    text-align: center;\n    font-weight: bold;\n}\n\n.sidebar-user a.active,\n.sidebar-user a:hover {\n    background-color: #e7f3ff;\n}\n\n.content {\n    flex: 1;\n    padding: 40px;\n    background-color: #f0f2f5;\n}\n\n.notification-page {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    height: 100%;\n}\n\n.empty-notification-image {\n    width: 150px;\n    height: 150px;\n    margin-bottom: 20px;\n}\n\n.notification-page p {\n    color: #555;\n    font-size: 18px;\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
