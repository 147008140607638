// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar {
    width: 100%;
    box-shadow: none;
}

.sidebar ul {
    list-style-type: none;
    padding: 0;
}

.sidebar .section-header {
    font-size: 1.2rem; 
    cursor: pointer;
    font-weight: bold;
    color: #1a237e; 
    padding: 10px 0;
}

.sidebar a p {
    font-size: 0.9rem; 
    margin: 0;
    padding: 5px 0;
    color: black; 
}

.sidebar a {
    text-decoration: none;
    color: inherit;
}

.sidebar a:hover p {
    color: #1a237e; 
}
`, "",{"version":3,"sources":["webpack://./src/components/education/css/sideBar.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,qBAAqB;IACrB,UAAU;AACd;;AAEA;IACI,iBAAiB;IACjB,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,SAAS;IACT,cAAc;IACd,YAAY;AAChB;;AAEA;IACI,qBAAqB;IACrB,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".sidebar {\n    width: 100%;\n    box-shadow: none;\n}\n\n.sidebar ul {\n    list-style-type: none;\n    padding: 0;\n}\n\n.sidebar .section-header {\n    font-size: 1.2rem; \n    cursor: pointer;\n    font-weight: bold;\n    color: #1a237e; \n    padding: 10px 0;\n}\n\n.sidebar a p {\n    font-size: 0.9rem; \n    margin: 0;\n    padding: 5px 0;\n    color: black; \n}\n\n.sidebar a {\n    text-decoration: none;\n    color: inherit;\n}\n\n.sidebar a:hover p {\n    color: #1a237e; \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
