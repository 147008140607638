// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* CertificateManage.css */
body {
    font-family: Arial, sans-serif;
}

.certificate {
    border: 1px solid #ccc;
    padding: 20px;
    width: 800px;
    margin: 0 auto;
}

.header {
    text-align: center;
    margin-bottom: 20px;
}

.header img {
    width: 100px;
}

.section {
    margin-bottom: 20px;
}

.section-title {
    background-color: #b3976d;
    padding: 5px;
    font-weight: bold;
}

.section-content {
    padding: 10px;
    border: 1px solid #ccc;
}

.grading-info {
    display: flex;
    justify-content: space-between;
}

.grading-info div {
    width: 48%;
}

.table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

.table th, .table td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
}

.footer {
    text-align: center;
    margin-top: 20px;
    font-size: 0.9em;
}
`, "",{"version":3,"sources":["webpack://./src/components/dashboard/dashboardContent/CertificateManage.css"],"names":[],"mappings":"AAAA,0BAA0B;AAC1B;IACI,8BAA8B;AAClC;;AAEA;IACI,sBAAsB;IACtB,aAAa;IACb,YAAY;IACZ,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,WAAW;IACX,yBAAyB;IACzB,gBAAgB;AACpB;;AAEA;IACI,sBAAsB;IACtB,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,gBAAgB;AACpB","sourcesContent":["/* CertificateManage.css */\nbody {\n    font-family: Arial, sans-serif;\n}\n\n.certificate {\n    border: 1px solid #ccc;\n    padding: 20px;\n    width: 800px;\n    margin: 0 auto;\n}\n\n.header {\n    text-align: center;\n    margin-bottom: 20px;\n}\n\n.header img {\n    width: 100px;\n}\n\n.section {\n    margin-bottom: 20px;\n}\n\n.section-title {\n    background-color: #b3976d;\n    padding: 5px;\n    font-weight: bold;\n}\n\n.section-content {\n    padding: 10px;\n    border: 1px solid #ccc;\n}\n\n.grading-info {\n    display: flex;\n    justify-content: space-between;\n}\n\n.grading-info div {\n    width: 48%;\n}\n\n.table {\n    width: 100%;\n    border-collapse: collapse;\n    margin-top: 10px;\n}\n\n.table th, .table td {\n    border: 1px solid #ccc;\n    padding: 8px;\n    text-align: left;\n}\n\n.footer {\n    text-align: center;\n    margin-top: 20px;\n    font-size: 0.9em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
