// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbarDropdownWrapper {
  position: absolute;
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: none;
  width: 100%;
  left: 0;
  top: 100%;
}

.navbar-item:hover .navbarDropdownWrapper {
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/css/navBar.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,uBAAuB;EACvB,0CAA0C;EAC1C,aAAa;EACb,aAAa;EACb,WAAW;EACX,OAAO;EACP,SAAS;AACX;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".navbarDropdownWrapper {\n  position: absolute;\n  background-color: white;\n  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);\n  z-index: 1000;\n  display: none;\n  width: 100%;\n  left: 0;\n  top: 100%;\n}\n\n.navbar-item:hover .navbarDropdownWrapper {\n  display: block;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
