// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ProductManage.css */
.container-fluid {
  padding: 20px;
  background-color: #f5f5f5;
  min-height: 100vh;
  width: 100%;
  margin: 0 auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.table-container {
  overflow-x: auto;
}



.table-container .MuiTable-root {
  width: 100%;
  border-collapse: collapse;
}

.table-container .MuiTableCell-head {
  background-color: #1976d2;
  color: #ffffff;
  font-weight: bold;
  font-size: xx-small;
}

.table-container .MuiTableCell-body {
  background-color: #ffffff;
  font-size: xx-small;
}
.table-container .MuiTableCell-body img {
  width: 20px;
  height: 20px;
}

.table-container .MuiTableRow-root:hover {
  background-color: #f1f1f1;
}

.add-product-button {
  margin: 20px 0;
  display: flex;
  justify-content: center;
}

.add-product-button button {
  background-color: #1976d2;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.add-product-button button:hover {
  background-color: #0056b3;
}

.add-product-button svg {
  font-size: 24px;
  margin-right: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/components/dashboard/dashboardContent/ProductManage.css"],"names":[],"mappings":"AAAA,sBAAsB;AACtB;EACE,aAAa;EACb,yBAAyB;EACzB,iBAAiB;EACjB,WAAW;EACX,cAAc;EACd,wCAAwC;EACxC,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;;;AAIA;EACE,WAAW;EACX,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;EACzB,cAAc;EACd,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,mBAAmB;AACrB;AACA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,cAAc;EACd,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,yBAAyB;EACzB,cAAc;EACd,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,eAAe;EACf,eAAe;EACf,iCAAiC;AACnC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB","sourcesContent":["/* ProductManage.css */\n.container-fluid {\n  padding: 20px;\n  background-color: #f5f5f5;\n  min-height: 100vh;\n  width: 100%;\n  margin: 0 auto;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n  border-radius: 8px;\n}\n\n.table-container {\n  overflow-x: auto;\n}\n\n\n\n.table-container .MuiTable-root {\n  width: 100%;\n  border-collapse: collapse;\n}\n\n.table-container .MuiTableCell-head {\n  background-color: #1976d2;\n  color: #ffffff;\n  font-weight: bold;\n  font-size: xx-small;\n}\n\n.table-container .MuiTableCell-body {\n  background-color: #ffffff;\n  font-size: xx-small;\n}\n.table-container .MuiTableCell-body img {\n  width: 20px;\n  height: 20px;\n}\n\n.table-container .MuiTableRow-root:hover {\n  background-color: #f1f1f1;\n}\n\n.add-product-button {\n  margin: 20px 0;\n  display: flex;\n  justify-content: center;\n}\n\n.add-product-button button {\n  background-color: #1976d2;\n  color: #ffffff;\n  border: none;\n  padding: 10px 20px;\n  border-radius: 8px;\n  font-size: 16px;\n  cursor: pointer;\n  transition: background-color 0.3s;\n}\n\n.add-product-button button:hover {\n  background-color: #0056b3;\n}\n\n.add-product-button svg {\n  font-size: 24px;\n  margin-right: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
